import * as React from 'react';
import {Header} from '../components/header/Header';
import './guides.scss';
import {Footer} from '../components/footer/Footer';
import {PageHead} from '../components/page-head/PageHead';
import { QAllGuides } from '../components/QAllGuides/QAllGuides';
import { AllGuides_allGuidesJson_nodes } from '../components/QAllGuides/__generated__/AllGuides';
import { QAllImages } from '../components/QAllImages/QAllImages';
import { AllImages } from '../components/QAllImages/__generated__/AllImages';
import { Container, Row } from 'react-bootstrap';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImageData } from '../utils/data';
import { slugify } from '../utils/slug';
import { Link } from 'gatsby';

type Guide = AllGuides_allGuidesJson_nodes;

const GuidesPage = () => {
  return (
    <>
      <PageHead/>
      <Header />
      <Container>
        <h2 className="text-center">Photography Guides</h2>
        <QAllGuides render={(allGuides: Guide[]) =>
          <QAllImages render={(allImages: AllImages) =>
            <Row>
              {allGuides.map((guide: Guide, index: number) =>
                <section className="guide col-xl-6 col-md-12"  key={index}>
                  <Link to={`/guides/${slugify(guide.title ?? '')}/`}>
                    <Row>
                      <GatsbyImage
                        className="col-md-6"
                        style={{height: '100%'}}
                        image={getImageData(guide.imageName, allImages)}
                        alt={guide.title ?? 'No Title'}
                      />
                      <div className="col-md-6 inner-text">
                        <h5>{guide.title}</h5>
                        <p>{guide.description}</p>
                      </div>
                    </Row>
                  </Link>
                </section>
              )}
            </Row>
          }/>
        }/>
      </Container>
      <Footer />
    </>
  );
};

export default GuidesPage;