import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react'
import { AllGuides, AllGuides_allGuidesJson_nodes } from './__generated__/AllGuides';

const query = graphql`
  query AllGuides {
    allGuidesJson {
      nodes {
        id
        imageName
        title
        description
      }
    }
  }`;

type QAllGuidesProps = {
  render: (allGuides: AllGuides_allGuidesJson_nodes[]) => React.ReactNode;
}

export const QAllGuides: React.FC<QAllGuidesProps> = ({render}: QAllGuidesProps) => {
  return <StaticQuery
    query={query}
    render={(data: AllGuides) => render(data.allGuidesJson.nodes)}/>;
};

